/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-01-30",
    versionChannel: "nightly",
    buildDate: "2024-01-30T00:15:06.684Z",
    commitHash: "1cf669c75e81654f83e20900a683cdb22e14a6d2",
};
